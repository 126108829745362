angular.module('sq.common.default.component', [])
.component('defaultView', {
  controller: function() {},
  template: `
    <md-button class='link' href="/submit">
      Start
    </md-button>
   <md-button class="link" href='/help'>
      Guide
   </md-button>
   <md-button class="link" href='/public'>
      Try
    </md-button>`,
});